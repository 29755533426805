<template>
    <div class="container p-4">
        <b-tabs content-class="mt-3" class="mt-3">
            <b-tab title="Games" active>
                <b-row>
                    <b-col>
                        <b-button
                            class="float-right"
                            variant="primary"
                            size="sm"
                            @click="newGame"
                        >
                            New Game
                        </b-button>
                    </b-col>
                </b-row>

                <b-row class="mt-3">
                    <b-col md="8" class="my-0">
                        <b-input-group prepend="Search" size="sm" class="mt-1">
                            <b-form-input
                                v-model="games.searchTitle"
                                placeholder="Title"
                            >
                            </b-form-input>
                            <b-input-group-append>
                                <b-button
                                    :disabled="!games.searchTitle"
                                    variant="primary"
                                    @click="loadGames"
                                >
                                    OK
                                </b-button>
                                <b-button
                                    :disabled="!games.searchTitle"
                                    variant="outline-primary"
                                    @click="clearGameSearch"
                                >
                                    Clear
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
                    <b-col>
                        <div class="float-right">
                            <b-pagination
                                v-model="games.currentPage"
                                @change="onGamesPageChange"
                                :total-rows="games.totalRows"
                                :per-page="games.perPage"
                                class="my-0"
                                size="sm"
                            >
                            </b-pagination>
                        </div>
                    </b-col>
                </b-row>

                <b-table
                    class="mt-3 w-auto table table-sm text-xsmall font-weight-light games-table"
                    :items="games.table.data"
                    :fields="games.table.fields"
                    :busy="games.loading"
                    current-page="games.currentPage"
                    hover
                    small
                    select-mode="single"
                    sticky-header
                    head-variant="light"
                    ref="gameTable"
                    selectable
                    show-empty
                    responsive
                    @row-selected="onGameRowSelect"
                >
                    <template #empty>
                        <div class="text-center text-muted my-2">
                            There are no records to show
                        </div>
                    </template>

                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            Loading...
                        </div>
                    </template>

                    <template #cell(status)="data">
                        <b-badge :variant="getStatusBadge(data.value)">{{
                            data.value
                        }}</b-badge>
                    </template>

                    <template #cell(progress)="data">
                        <div class="mt-1">
                            <b-progress
                                :value="data.value.day"
                                :max="data.value.total_days - 1"
                                :variant="getStatusBadge(data.item.status)"
                            >
                            </b-progress>
                        </div>
                    </template>

                    <template #cell(info)="data">
                        <span>
                            <i>{{ data.value }}</i>
                        </span>
                    </template>
                </b-table>
            </b-tab>

            <b-tab title="Templates">
                <b-row>
                    <b-col>
                        <b-button
                            class="float-right"
                            variant="primary"
                            size="sm"
                            @click="toggleTempsShow"
                        >
                            {{ temps.showPublic ? 'Show Mine' : 'Show Public' }}
                        </b-button>
                    </b-col>
                </b-row>

                <b-row class="mt-3">
                    <b-col md="8" class="my-0">
                        <b-input-group size="sm" prepend="Search" class="mt-1">
                            <b-form-input
                                v-model="temps.searchTitle"
                                placeholder="Title"
                            >
                            </b-form-input>
                            <b-input-group-append>
                                <b-button
                                    :disabled="!temps.searchTitle"
                                    variant="primary"
                                    @click="loadTemps"
                                >
                                    OK
                                </b-button>
                                <b-button
                                    :disabled="!temps.searchTitle"
                                    variant="outline-primary"
                                    @click="clearTempSearch"
                                >
                                    Clear
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
                    <b-col>
                        <div class="float-right">
                            <b-pagination
                                v-model="temps.currentPage"
                                @change="onTempsPageChange"
                                :total-rows="temps.totalRows"
                                :per-page="temps.perPage"
                                class="my-0"
                                size="sm"
                            >
                            </b-pagination>
                        </div>
                    </b-col>
                </b-row>

                <b-table
                    class="mt-3 w-auto table table-sm text-xsmall font-weight-light games-table"
                    :items="temps.table.data"
                    :fields="temps.table.fields"
                    :busy="temps.loading"
                    current-page="temps.currentPage"
                    hover
                    small
                    select-mode="single"
                    sticky-header
                    head-variant="light"
                    ref="tempTable"
                    selectable
                    show-empty
                    responsive
                    @row-selected="onTempRowSelect"
                >
                    <template #empty>
                        <div class="text-center text-muted my-2">
                            There are no records to show
                        </div>
                    </template>

                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            Loading...
                        </div>
                    </template>

                    <template #cell(info)="data">
                        <span>
                            <i>{{ data.value }}</i>
                        </span>
                    </template>
                </b-table>
            </b-tab>
        </b-tabs>
    </div>
</template>
<script>
import lodash from 'lodash'
import { formatDateString, gameStatusBadgeMap } from '@/views'
import ApiService from '@/services/api.service'

export default {
    data() {
        return {
            games: {
                searchTitle: null,
                currentPage: 1,
                totalRows: 0,
                perPage: 8,
                loading: true,
                data: null,
                table: {
                    data: [],
                    fields: [
                        'title',
                        'created',
                        'start',
                        'progress',
                        'finish',
                        'status',
                        'teams',
                    ],
                },
            },
            temps: {
                searchTitle: null,
                currentPage: 1,
                totalRows: 0,
                perPage: 8,
                showPublic: false,
                loading: true,
                data: null,
                table: {
                    data: [],
                    fields: ['title', 'info', 'created', 'designer', 'games'],
                },
            },
        }
    },
    mounted() {
        this.loadGames()
        this.loadTemps()
    },
    methods: {
        newGame() {
            this.$router.push({ name: 'designGame' }).catch((err) => {})
        },
        onGameRowSelect(items) {
            this.$router
                .push({ name: 'designGame', params: { gameId: items[0].id } })
                .catch((err) => {})
        },
        onTempRowSelect(items) {
            this.$router
                .push({
                    name: 'designTemplate',
                    params: { tempId: items[0].id },
                })
                .catch((err) => {})
        },
        getStatusBadge(status) {
            return gameStatusBadgeMap[status]
        },
        toggleTempsShow() {
            this.temps.showPublic = !this.temps.showPublic
            this.loadTemps()
        },
        clearGameSearch() {
            this.games.searchTitle = null
            this.loadGames()
        },
        onGamesPageChange(e) {
            this.games.currentPage = e
            this.loadGames()
        },
        loadGames() {
            this.games.loading = true

            this.games.data = null
            this.games.table.data = []

            ApiService.getGame({
                title: this.games.searchTitle,
                offset: (this.games.currentPage - 1) * this.games.perPage,
                limit: this.games.perPage,
            }).then(
                (response) => {
                    this.games.data = response.data.data
                    this.games.totalRows = parseInt(
                        lodash.get(response.headers, 'x-total-count', 0)
                    )

                    this.games.data.forEach((game) => {
                        this.games.table.data.push({
                            id: game.id,
                            title: game.title,
                            created: formatDateString(game.created_at),
                            start: !!game.start_at
                                ? formatDateString(game.start_at)
                                : '',
                            finish: !!game.stopped_at
                                ? formatDateString(game.stopped_at)
                                : '',
                            status: game.status,
                            progress: game.progress,
                            teams: game.teams.length,
                        })
                    })

                    this.games.loading = false
                },
                (error) => {
                    this.games.loading = false
                    this.$bvToast.toast(error.response.data.message, {
                        title: 'Error',
                        toaster: 'b-toaster-top-center',
                        variant: 'danger',
                        solid: true,
                    })
                }
            )
        },
        clearTempSearch() {
            this.temps.searchTitle = null
            this.loadTemps()
        },
        onTempsPageChange(page) {
            this.temps.currentPage = page
            this.loadTemps()
        },
        loadTemps() {
            this.temps.loading = true

            this.temps.data = null
            this.temps.table.data = []

            ApiService.getTemplate({
                title: this.temps.searchTitle,
                showPublic: this.temps.showPublic,
                offset: (this.temps.currentPage - 1) * this.temps.perPage,
                limit: this.temps.perPage,
            }).then(
                (response) => {
                    this.temps.data = response.data.data
                    this.temps.totalRows = parseInt(
                        lodash.get(response.headers, 'x-total-count', 0)
                    )

                    this.temps.data.forEach((temp) => {
                        this.temps.table.data.push({
                            id: temp.id,
                            title: temp.title,
                            info: temp.description,
                            created: formatDateString(temp.created_at),
                            designer: temp.designer.name,
                            games: temp.games,
                        })
                    })

                    this.temps.loading = false
                },
                (error) => {
                    this.temps.loading = false
                    this.$bvToast.toast(error.response.data.message, {
                        title: 'Error',
                        toaster: 'b-toaster-top-center',
                        variant: 'danger',
                        solid: true,
                    })
                }
            )
        },
    },
}
</script>
<style>
td span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 200px;
}
</style>
